import React, { useState } from "react";
import Interface from "../../Rcompo/Interface";
import { Link, useNavigate } from "react-router-dom";
import globalAPIAddress from "../GlobalPages/GlobalVar";
import axios from "axios"
export default function Cars({
  head1,
  label3,
  label4,
  label5,
  label6,
  Year,
  Make,
  Model,
  buttonLabel1,
}) {
  const navigate = useNavigate();
  const [year, setyear] = useState("");
  const [model, setmodel] = useState("");
  const [vin, setVIN] = useState("");
  const [make, setmake] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [operable, setoperable] = useState(null);
  const handleOptionChange = (value) => {
    setoperable(value);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleUpload = () => {
    // Perform upload logic here (e.g., send selectedFile to server)
    console.log("File uploaded:", selectedFile);
    // Reset selected file after upload
    setSelectedFile(null);
  };

  const handleyearChange = (e) => {
    setyear(e.target.value);
  };

  const handlemodelChange = (e) => {
    setmodel(e.target.value);
  };
  const handlemakeChange = (e) => {
    setmake(e.target.value);
  };
  const HandleSubmit = () => {
    axios.post(`${globalAPIAddress}/Dest`,
    {
      year: year,
      make: make,
      model : model, 
      vin: vin, 
      operable: operable
    }
    ).then(response => {
        console.log(response.data.message);
        navigate("/Shipment-Date")
    }).catch(error => {
         console.log(error.response.data); 
    });
    }
  return (
    <Interface>
      <div className="mx-7 md:mx-10 lg:mx-32 px-4 lg:px-10 py-4 lg:py-5 max-w-full mt-4 lg:mt-10 mb-8 lg:mb-24 bg-white shadow-2xl shadow-gray-300 rounded-md lg:rounded-lg">
        <h2 className="font-semibold text-xl mb-4">{head1}</h2>
        <div className="grid lg:grid-cols-2 gap-4">
          <div className="col-span-1">
            <div className="pr-4">
              <div className="mb-4">
                <label
                  htmlFor="dropdown1"
                  className="block mb-1 font-semibold text-gray-700"
                >
                  {label3}
                </label>
                <select
                  id="dropdown1"
                  className=" w-full  h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  value={year}
                  onChange={handleyearChange}
                >
                  <option value="">Select a {label3}</option>
                  {Year.map((option, index) => (
                    <option key={index} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="Text"
                  className="block mb-1 font-semibold text-gray-700"
                >
                  {label4}
                </label>
                <select
                  id="Text"
                  className="w-full  h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  value={Model}
                  onChange={handlemodelChange}
                >
                  <option value="">Select a {label4}</option>
                  {Model.map((option, index) => (
                    <option key={index} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="dropdown3"
                  className="block mb-1 font-semibold text-gray-700"
                >
                  {label5}
                </label>
                <select
                  id="dropdown3"
                  className="w-full  h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  value={make}
                  onChange={handlemakeChange}
                >
                  <option value="">Select a {label5}</option>
                  {Make.map((option, index) => (
                    <option key={index} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-span-1">
            <div>
              <label className="block font-semibold text-gray-700 ">
                {label6}
                <br></br>
                <input
                  className=" w-64  mt-1 h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  type="text"
                  placeholder="Enter VIN..."
                  onChange={(e)=>{
                    setVIN(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="flex items-center mt-5 ">
              <span className="block mb-1 font-medium text-gray-700">
                Is it operable?
              </span>
              <label className="inline-flex items-center mr-4">
                <input
                  type="radio"
                  className="ml-3 form-radio h-5 w-5 text-indigo-600"
                  value="year"
                  checked={operable === "Opened"}
                  onChange={() => handleOptionChange("Opened")}
                />
                <span className="ml-2 ">Yes</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio h-5 w-5 text-indigo-600"
                  value="model"
                  checked={operable === "Enclosed"}
                  onChange={() => handleOptionChange("Enclosed")}
                />
                <span className="ml-2">Enclosed</span>
              </label>
            </div>
            <div className="">
              <span className="block mt-1 font-semibold lg:font-semibold   text-gray-700 mb-4">
                Documentation related to the vehicle's ownership( registration
                and proof of insurance.)
              </span>
              <input
                type="file"
                onChange={handleFileChange}
                className="  py-2 px-1  lg:px-4 "
              />
              {selectedFile && (
                <div className="mt-4">
                  <p className="font-medium">
                    Selected File: {selectedFile.name}
                  </p>
                  <button
                    onClick={handleUpload}
                    className="mt-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
                  >
                    Upload
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-7 lg:mt-14 flex justify-start space-x-24 ml-2 ">
          <Link to="/PostShipment">
            <button className=" bg-gray-600 hover:bg-gray-500 text-white font-bold py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap">
              Back
            </button>
          </Link>
     
            <button onClick={HandleSubmit}
             className="bg-red-500 hover:bg-red-600 text-white font-bold  py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap">
              {buttonLabel1}
            </button>
        </div>
      </div>
    </Interface>
  );
}
