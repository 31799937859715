import React, { useState } from "react";
import DriverInterface from "../../Rcompo/DriverInterface";
import { NavLink, useNavigate } from "react-router-dom";

export default function MyVehicles({ children }) {
  const [activeLink, setActiveLink] = useState("");
  const navigate = useNavigate();

  const handleOptionChange = (selectedOption) => {
    setActiveLink(selectedOption);
    navigate(`/${selectedOption}`);
  };

  const listofbuttons = [
    "Listed",
    "Not Signed",
    "Dispatched",
    "Pic-Up",
    "Delivered",
    "Cancelled",
    "Archived",
  ];
  return (
    <DriverInterface>
      <div className=" mt-14 mx-7 md:mx-10   lg:mx-36">
        <div className="px-6 bg-white rounded-lg py-4 font-semibold text-xl shadow-md shadow-gray-300 ">
          <span className="font-semibold text-xl">My Vehicles</span>
          {/* Flex */}
          <div className=" mt-5 font-sm hidden lg:flex xl:flex items-center justify-around">
            {listofbuttons.map((option, index) => (
              <NavLink
                onClick={() => setActiveLink(option)}
                to={`/${option}`}
                className={`mx-3 p-2 text-sm rounded-md ${
                  activeLink === option
                    ? "bg-red-600 text-white"
                    : "bg-gray-300"
                }`}
              >
                <span>{option}</span>
              </NavLink>
            ))}
          </div>
          <div className="mt-5 font-sm w-full py-3 flex lg:hidden xl:hidden items-center justify-around">
            <select
              className="w-full focus:outline-none border-2 border-gray-300 rounded-lg"
              onChange={(e) => handleOptionChange(e.target.value)}
            >
              {listofbuttons.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          {/* Flex */}
        </div>

        <div>{children}</div>
      </div>
    </DriverInterface>
  );
}
