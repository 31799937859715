import React from "react";
import Interface from "../../Rcompo/Interface";
import Progress from "./Progress";
import VehicleListings from "./VehicleListings";
export default function Billings() {
  const daysLeft = 260;
  let postsleft = 30;
  const roll = "Interface";
  return (
    <>
      <Interface>
        <div className="lg:px-8 mx-7 md:mx-10   lg:mx-4">
          <div className="text-xl sm:text-xl lg:text-3xl mt-6 mb-4 ml-8">
            Billing
          </div>
          <hr className="mb-6 mx-8"></hr>
          <div className="border  w-full h-fit mt-6  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold h-fit flex justify-between px-4 py-2 mb-4 bg-slate-300  rounded-tr-xl rounded-tl-xl"
            >
              <div>
                <p className="mx-4">Account Status</p>
              </div>
              <div>
                <button>
                  {" "}
                  <p className="text-blue-500 hover:underline font-medium text-lg mr-4">
                    Print Statement
                  </p>
                </button>
              </div>
            </div>
            <div className="px-4 py-4">
              <div className="px-4 py-4 flex">
                <div className="w-1/2 pr-2">
                  <p className="text-sm lg:text-md ml-4 mb-1">
                    No Payment Due:{" "}
                  </p>
                  <p className="text-sm lg:text-md ml-4 font-semibold">$0.00</p>
                </div>
                <div className="w-1/2 pl-2">
                  <div className="mb-4">
                    <p className="text-sm lg:text-md mb-1">
                      Last Payment Received:{" "}
                    </p>
                    <p className="text-sm lg:text-md font-semibold">$97.95</p>
                  </div>
                  <div className="mb-4">
                    <p className="text-sm lg:text-md mb-1">
                      Last Payment Date:{" "}
                    </p>
                    <p className="text-sm lg:text-md font-semibold">
                      12/30/2023
                    </p>
                  </div>
                  <div className="mb-4">
                    <p className="text-sm lg:text-md mb-1">Method: </p>
                    <p className="text-sm lg:text-md font-semibold">Discover</p>
                  </div>

                  <div className="mb-0">
                    <p className="text-sm lg:text-md mb-1">
                      Automatic Payment:{" "}
                      <span className="text-green-500 text-sm lg:text-md font-bold">
                        ON
                      </span>{" "}
                    </p>
                  </div>

                  <div className="mb-4">
                    <button className="hover:underline text-blue-500">
                      {" "}
                      <p className="text-sm lg:text-md mb-1">
                        Disable Automatic Payment
                      </p>
                    </button>
                  </div>

                  <div className="mb-4 ">
                    <p className="text-sm lg:text-md mb-1">
                      Paperless Billing:{" "}
                      <span className="text-green-500 text-md font-bold">
                        ON
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Second Box ANd the Boths are flex */}
          <div className="lg:flex md:flex ">
            <div className="border md:mr-4 lg:mr-4  lg:w-1/2 md:w-1/2  mt-6  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
              <div
                style={{ backgroundColor: "#F5F5F5" }}
                className="text-sm md:text-xl sm:text-lg font-semibold h-fit flex justify-between px-4 py-2 mb-4 bg-slate-300  rounded-tr-xl rounded-tl-xl"
              >
                <div>
                  <p className="mx-4">Billing Information</p>
                </div>
                <div>
                  <button>
                    {" "}
                    <p className="text-blue-500 hover:underline font-medium text-lg mr-4">
                      Edit
                    </p>
                  </button>
                </div>
              </div>
              <div className="flex justify-between mx-4 mb-2">
                <div className="text-sm lg:text-md ml-4 mb-1">
                  Billing Contact{" "}
                </div>
                <div className="text-sm lg:text-md mr-8 font-semibold">
                  Hussain Bhai
                </div>
              </div>

              <div style={{ backgroundColor: "#F5F5F5" }}>
                <div className="flex justify-between mx-4 mb-2">
                  <div className="text-sm lg:text-md ml-4 mb-1">
                    Billing Address{" "}
                  </div>
                  <div className="text-sm lg:text-md mr-8 font-semibold">
                    Dopment D12
                  </div>
                </div>
              </div>

              <div className="flex justify-between mx-4 mb-2">
                <div className="text-sm lg:text-md ml-4 mb-1">
                  Billing City{" "}
                </div>
                <div className="text-sm lg:text-md mr-8 font-semibold">
                  Islamabad
                </div>
              </div>

              <div style={{ backgroundColor: "#F5F5F5" }}>
                <div className="flex justify-between mx-4 mb-2">
                  <div className="text-sm lg:text-md ml-4 mb-1">
                    Billing State{" "}
                  </div>
                  <div className="text-sm lg:text-md mr-8 font-semibold">
                    Fedral
                  </div>
                </div>
              </div>

              <div className="flex justify-between mx-4 mb-2">
                <div className="text-sm lg:text-md ml-4 mb-1">Billing Zip </div>
                <div className="text-sm lg:text-md mr-8 font-semibold">
                  44000
                </div>
              </div>

              <div style={{ backgroundColor: "#F5F5F5" }}>
                <div className="flex justify-between mx-4 mb-2">
                  <div className="text-sm lg:text-md ml-4 mb-1">Phone </div>
                  <button>
                    <div className="text-blue-500 hover:underline text-sm lg:text-md mr-8">
                      0345890039
                    </div>
                  </button>
                </div>
              </div>

              <div className="flex justify-between mx-4 mb-2">
                <div className="text-sm lg:text-md ml-4 mb-1">email </div>
                <div className="text-sm lg:text-md mr-8 font-semibold">
                  hussaind12@dopment.com
                </div>
              </div>
            </div>

            <div className="border lg:w-1/2 md:w-1/2  h-fit  mt-6  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
              <div
                style={{ backgroundColor: "#F5F5F5" }}
                className="text-sm md:text-xl sm:text-lg font-semibold  h-fit flex justify-between px-4 py-2 mb-4 bg-slate-300  rounded-tr-xl rounded-tl-xl"
              >
                <div>
                  <p className="mx-4">Membership</p>
                </div>
                <div>
                  <button>
                    {" "}
                    <p className="text-blue-500 hover:underline font-medium text-lg mr-4">
                      Edit
                    </p>
                  </button>
                </div>
              </div>
              <div className="flex justify-between mx-4 mb-4">
                <div className="text-sm lg:text-md ml-4 mb-1">
                  Current Membership{" "}
                </div>
                <div className="text-sm lg:text-md mr-2">
                  Up to 6 posts/month
                </div>
              </div>
              <div className="hover:bg-gray-100">
                <div className="flex justify-between mx-4 mb-4">
                  <div className="text-sm lg:text-md ml-4 mb-1">Bicycle </div>
                  <div className="text-sm lg:text-md mr-2 font-semibold">
                    260 days left
                  </div>
                </div>
                <div className="mx-8 mb-8">
                  <Progress daysLeft={daysLeft} />
                </div>
              </div>

              <div className="hover:bg-gray-100">
                <div className="flex justify-between mx-4 mb-4">
                  <div className="text-sm lg:text-md ml-4 mb-1">
                    Vehicle Listings
                  </div>
                  <div className="text-sm lg:text-md mr-2 font-semibold">
                    6 Posts left
                  </div>
                </div>
                <div className="mx-8 mb-8">
                  <VehicleListings left={postsleft} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Interface>
    </>
  );
}
