import React, { useState, useEffect } from "react";
import Interface from "../../Rcompo/Interface";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"
import globalAPIAddress from "../GlobalPages/GlobalVar";
export default function Payment() {
  // const [Path, setPath] = useState("/Preview");
  const [balance, setbalance] = useState(0);
  const [Price, setPrice] = useState(0);
  const [COD, setCOD] = useState("");
  const [Btime, setBT] = useState(0);
  const [BTerm, setBt] = useState(0);
  const [BMethod, setBM] = useState(0);
  const [CODM, setCODM] = useState(0);
  const [CODL, setCODL] = useState(0);
  const [isbalancepositive, setisbalancepositive] = useState(false);
  const [isCODpositive, setisCODpositive] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setbalance(Price - COD);
    setisbalancepositive(Price - COD > 0);
    setisCODpositive(COD > 0);
  }, [Price, COD]);
  let handleChange = (e, type) => {
    const value = e.target.value;
    if (type === "Price") {
      setPrice(value);
    } else {
      setCOD(value);
    }
  };
  // const changepath = () => {
  //   if (Path === "/SearchVehicle") {
  //     setPath("/Post a Shipment");
  //   } else {
  //     setPath("/SearchVehicle");
  //   }
  // };
  const HandleSubmit = () => {
    axios.post(`${globalAPIAddress}/Payment`,
    {
      Balance: balance,
      PTC: Price,
      COD_P : COD, 
      COD_P_Method: CODM,
      COD_P_Loc: CODL,
      BPM: BMethod,
      BPT: Btime,
      BPTerm: BTerm,
    }
    ).then(response => {
        console.log(response.data.message);
        navigate("/Preview")
    }).catch(error => {
         console.log(error.response.data); 
    });
    }

  return (
    <Interface>
      <div className="mx-7 md:mx-10 lg:mx-32 px-4 lg:px-10 py-4 lg:py-5 max-w-full mt-4 lg:mt-10 mb-8 lg:mb-24 bg-white shadow-2xl shadow-gray-300 rounded-md lg:rounded-lg ">
        <div className="grid grid-cols-4 ">
          <h2 className="font-semibold text-xl mb-4 col-span-4">Payment</h2>
          <div className="mb-4 col-span-4">
            <label
              htmlFor="Price"
              className="block font-semibold text-md lg:text-lg text-gray-700"
            >
              Price to Pay Carrier
            </label>
            <div className="flex">
              <span className="p-2 lg:p-5 bg-gray-300 rounded-l-md">$</span>
              <input
                type="text"
                id="Price"
                name="Price"
                value={Price}
                onChange={(e) => handleChange(e, "Price")}
                className="p-3 block h-11 lg:h-16 w-full border-2 border-gray-300 rounded-r-md shadow-sm focus:ring focus:border-blue-500"
              />
            </div>
          </div>
          <div className="mb-4 col-span-2 mr-5">
            <label
              htmlFor="COD"
              className="block font-semibold  text-sm md:text-lg lg:text-lg text-gray-700"
            >
              COD/COP Amount
            </label>
            <div className="flex">
              <span className="p-2 lg:p-5 bg-gray-300 rounded-l-md">$</span>
              <input
                type="text"
                id="COD"
                name="COD"
                value={COD}
                onChange={(e) => handleChange(e, "COD")}
                className=" p-3 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-r-md shadow-sm focus:ring focus:border-blue-500"
              />
            </div>
          </div>

          <div className="col-span-2">
            <label
              htmlFor="COD"
              className="block font-semibold text-sm md:text-lg lg:text-lg text-gray-700 mb-0 "
            >
              Balance
            </label>
            <div className="flex">
              <span className="p-2 lg:p-5 bg-gray-300 rounded-l-md">$</span>
              <span className="p-3 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-r-md shadow-sm focus:ring focus:border-blue-500">
                {balance}
              </span>
            </div>
          </div>
          <div className="col-span-2 mt-4">
            {isCODpositive && (
              <div className="mb-4 mr-5">
                <div className="">
                  <label className="block font-semibold text-md lg:text-lg text-gray-700">
                    COD/COP Payment Method
                  </label>
                  <select onChange={(e)=>{
                    setCODM(e.target.value)
                  }}
                  className="p-1 lg:p-3 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500">
                    <option disabled>select</option>
                    <option>Cash/Certified Funds</option>
                    <option>Check</option>
                  </select>
                </div>
                <div className="mt-4">
                  <label className="block font-semibold text-md lg:text-lg text-gray-700">
                    COD/COP Location
                  </label>
                  <select onChange={(e)=>{
                    setCODL(e.target.value)
                  }}
                  className="p-1 lg:p-3 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500">
                    <option disabled>select</option>
                    <option>Delivery</option>
                    <option>Pickup</option>
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className="col-span-2 mt-4">
            {isbalancepositive && (
              <div className="mb-4">
                <div>
                  <label className="block font-semibold text-md lg:text-lg text-gray-700">
                    Balance Payment Method
                  </label>
                  <select onChange={(e)=>{
                    setBM(e.target.value)
                  }}
                  className="p-1 lg:p-3 mt-6 lg:mt-0 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500">
                    <option disabled>select</option>
                    <option>Cash</option>
                    <option>Certified Funds</option>
                    <option>Company Check</option>
                    <option>Comcheck</option>
                    <option>TCH</option>
                  </select>
                </div>
                <div className="mt-4">
                  <label className="block font-semibold text-md lg:text-lg text-gray-700">
                    Balance Payment Time
                  </label>
                  <select onChange={(e)=>{
                    setBT(e.target.value)
                  }}
                   className="p-1 lg:p-3 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500">
                    <option disabled>select</option>
                    <option>Immediately</option>
                    <option>
                      2 Business Days {"("}Quick Pay{")"}
                    </option>
                    <option>5 Business Days</option>
                    <option>10 Business Days</option>
                    <option>15 Business Days</option>
                    <option>30 Business Days</option>
                  </select>
                </div>
                <div className="mt-4">
                  <label className="block font-semibold text-md lg:text-lg text-gray-700">
                    Balance Payment Terms Begin On
                  </label>
                  <select onChange={(e)=>{
                    setBt(e.target.value)
                  }} 
                  className="p-1 lg:p-3 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500">
                    <option disabled>select</option>
                    <option>Pickup</option>
                    <option>Delivery</option>
                    <option>Receiving a Signed Bill of Lading</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full mt-3 lg:mt-14">
          <input
            type="checkbox"
            className="mr-3"
            id="PathSelect"
            // onClick={}
          />
          <label htmlFor="PathSelect">Submit Shipment and Upload another</label>
        </div>
        <div className="mt-7 lg:mt-14 flex justify-start space-x-20 ">
          <Link to="/Shipment-Date">
            <button className="bg-gray-600 hover:bg-gray-500 text-white font-bold py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap">
              Back
            </button>
          </Link>
          {/* <Link to={Path}> */}
            <button onClick={HandleSubmit}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap">
              Submit Shipment
            </button>
          {/* </Link> */}
        </div>
      </div>
    </Interface>
  );
}
