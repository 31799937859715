import React, { useState,useEffect } from "react";
import DriverInterface from "../../Rcompo/DriverInterface";
import Notification from "../../Rcompo/Notification";
import globalAPIAddress from "../GlobalPages/GlobalVar";
import axios from "axios";

export default function FindShipments({ Sort }) {
  const [items,setItems]=useState([])
  useEffect(()=>{
    const fetchItems=async()=>{
      try{
        const response = await axios.get(`${globalAPIAddress}/DriverNoti`);
      setItems(response.data)
      console.log(response.data)
    
  }
    catch (error) {
      console.error(error);
    }
  };
  fetchItems();
  },[])
  const handleIconClick = (icon) => {
    alert(`Clicked ${icon} button`);
  };
  const Array = [99.99, 69.99, 299.99, 149.99];
  const handleIconClick1 = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setCallNowVisible(!isCallNowVisible);
  };
  const [activeLink, setActiveLink] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCallNowVisible, setCallNowVisible] = useState(true);
  return (
    <DriverInterface>
      <div className="h-full mx-7 md:mx-10 lg:mx-32">
        <div className="mt-6 px-3 py-2 lg:mt-14 lg:mb-10 shadow-2xl shadow-gray-300 lg:px-6 bg-white rounded-lg lg:py-4">
          <span className="font-semibold text-xl">
            All Available Listings (0)
          </span>
          <div className="mt-5 ">
            {/* Flex */}
            <div className="px-3 lg:pl-6 font-sm flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center lg:justify-around">
              <div className="flex">
                <span>Sort by</span>
                {/* Sort By  */}
                <button
                  onClick={() => handleIconClick("Pick-Up Location")}
                  className="ml-2 flex items-center px-3 lg:px-2 py-0 bg-gray-300 rounded "
                >
                  {Sort}
                  <img
                    src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
                    alt="Image1"
                    className="w-3 h-3 ml-1"
                  />
                  {/* button Pickup */}
                </button>
                <div className=" ml-2">
                  <button
                    onClick={() => handleIconClick("Up Arrow")}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/up-arrow-white.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1  rounded-full bg-red-500 color-white"
                    />
                  </button>
                </div>

                <div className="ml-1">
                  <button
                    onClick={() => handleIconClick("Down Arrow")}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/down-arrow-red.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1"
                    />
                  </button>
                  {/* Arrows with the Images and Background */}
                </div>
              </div>
              <div className="pl-0 lg:pl-6 font-sm flex items-center">
                <span>thenby</span>
                {/* Sort By  */}
                <button
                  onClick={() => handleIconClick("Delivery Metro aria")}
                  className="ml-2 flex items-center px-2   py-0 bg-gray-300 rounded "
                >
                  DeliveryMetroaria
                  <img
                    src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
                    alt="Image1"
                    className="w-3 h-3 ml-1"
                  />
                  {/* button Pickup */}
                </button>
                <div className=" ml-2">
                  <button
                    onClick={() => handleIconClick("Up Arrow")}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/up-arrow-white.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1  rounded-full bg-red-500 color-white"
                    />
                  </button>
                </div>

                <div className="ml-1">
                  <button
                    onClick={() => handleIconClick("Down Arrow")}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/down-arrow-red.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1"
                    />
                  </button>
                  {/* Arrows with the Images and Background */}
                </div>
              </div>
              {/* Up Arrow ANd Down Arrow After the Aria */}

              <div className="pl-0 lg:pl-12 font-sm flex items-center">
                <span>View</span>
                {/* View */}
                <a>
                  <img
                    src={process.env.PUBLIC_URL + "/images/grid-red.png"}
                    alt="Image1"
                    className="w-6 h-6 ml-1"
                  />
                </a>
                {/* Grid Image*/}
                <a>
                  <img
                    src={process.env.PUBLIC_URL + "/images/brochure.png"}
                    alt="Image1"
                    className="w-5 h-5 ml-1"
                  />
                </a>
                {/* 2nd Grid Image*/}
              </div>

              <div className="pl-0 lg:pl-11 font-sm flex items-center">
                <span>Per Page</span>
                {/* Per Page */}
                <button className="ml-2 flex items-center px-1 py-0 bg-gray-300 rounded ">
                  50
                  <img
                    src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
                    alt="Image1"
                    className="w-3 h-3 ml-1"
                  />
                  {/* button Pickup */}
                </button>
                {/* Per Page */}
              </div>
            </div>
            {/* Flex */}
          </div>
        </div>
        {/* popup container */}
        {items.map((item) => (
  <Notification key={item.id} button="Interested" PTC={item.PTC} Location={item.Location} To={item.To} From={item.From} Year={item.Year} Make={item.Make} Model={item.Model} Vin={item.Vin} Destination={item.Destination} Operatble={item.Operatble}/>
))}


      </div>
    </DriverInterface>
  );
}
