import React from "react";
import MyVehicles from "./MyVehicles";
import { Link } from "react-router-dom";

export default function Listed() {
  const arr = [1, 2, 3, 4];
  return (
    <MyVehicles>
      {/* <div className="mt-8 mb-8 px-6 bg-white rounded-lg py-4 text-lg shadow-md shadow-gray-300">
        Hello
      </div> */}
      <div className="my-8 px-6  bg-white rounded-lg py-4 text-lg shadow-md shadow-gray-300">
        {/* content here */}
        <div className="mt-6" id="Container">
          <div className="text-sm px-2 lg:pl-7 font-semibold flex items-center justify-start">
            <span className="mr-3 lg:mr-8">Serial</span>
            <span className="">Vehicle Information</span>
          </div>
          {arr.map((option, index) => (
            <div>
              <div className="flex shadow-md px-2 py-4 lg:p-7 shadow-gray-300 items-center justify-start">
                <div className=" mr-1">
                  <span>{option}</span>
                </div>

                <div className="mr-7 ml-7 lg:mr-14 lg:ml-14">
                  <span className="text-sm">
                    2014 Audi Q5{" "}
                    <span className="font-semibold">Order ID:</span> Q5. txt
                    786.7070.849
                  </span>
                </div>

                <div className=" ">
                  <Link
                    className="flex items-center justify-start text-sm mb-1 bg-red-600 text-white py-1 px-2 lg:px-5 rounded-md"
                    to="/IntrestedShipper"
                  >
                    <div className=" w-5 mr-2 pl-1 bg-white rounded-full">
                      <span className="text-black">{  `${option}`}</span>
                    </div>
                    Intrested Shippers
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MyVehicles>
  );
}