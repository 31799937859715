const options1 = [
  { label: "Option 1", value: "Option 1" },
  { label: "Option 2", value: "Option 2" },
  { label: "Option 3", value: "Option 3" },
];

const options2 = [
  { label: "Option A", value: "Option A" },
  { label: "Option B", value: "Option B" },
  { label: "Option C", value: "Option C" },
];

const Year = [
  { label: "2000", value: "Option 1" },
  { label: "2010", value: "Option 2" },
  { label: "2023", value: "Option 3" },
];

const Make = [
  { label: "Honda", value: "Option A" },
  { label: "Toyota", value: "Option B" },
  { label: "BMW", value: "Option C" },
];
const Model = [
  { label: "Civic", value: "Option 1" },
  { label: "Grande", value: "Option 2" },
  { label: "X7", value: "Option 3" },
];

export { options1, options2, Year, Make, Model };
