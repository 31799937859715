import React, { useState } from "react";
import Interface from "../../Rcompo/Interface";
import { Link } from "react-router-dom";

export default function MyShipment({ Sort }) {
  const handleIconClick = (icon) => {
    alert(`Clicked ${icon} button`);
  };
  // const driversArray = [1, 2, 3, 4];
  const Array = [88.99, 39.99, 90.99, 105.99];

  const handleIconClick1 = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setCallNowVisible(!isCallNowVisible);
  };
  const [activeLink, setActiveLink] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCallNowVisible, setCallNowVisible] = useState(true);
  return (
    <Interface>
      <div className="h-full  mx-7 md:mx-10 lg:mx-32">
        <div className="mt-6 px-3 py-2 lg:mt-10 lg:mb-10 shadow-2xl shadow-gray-300  lg:px-6 bg-white rounded-lg lg:py-4">
          <span className="font-semibold text-xl">
            All Available Listings (0)
          </span>
          <div className="mt-5 ">
            {/* Flex */}
            <div className="px-3 lg:pl-6 font-sm flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center lg:justify-around">
              <div className="flex">
                <span>Sort by</span>
                {/* Sort By  */}
                <button
                  onClick={() => handleIconClick("Pick-Up Location")}
                  className="ml-2 flex items-center px-3 lg:px-2 py-0 bg-gray-300 rounded "
                >
                  {Sort}
                  <img
                    src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
                    alt="Image1"
                    className="w-3 h-3 ml-1"
                  />
                  {/* button Pickup */}
                </button>
                <div className=" ml-2">
                  <button
                    onClick={() => handleIconClick("Up Arrow")}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/up-arrow-white.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1  rounded-full bg-red-500 color-white"
                    />
                  </button>
                </div>

                <div className="ml-1">
                  <button
                    onClick={() => handleIconClick("Down Arrow")}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/down-arrow-red.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1"
                    />
                  </button>
                  {/* Arrows with the Images and Background */}
                </div>
              </div>
              <div className="pl-0 lg:pl-6 font-sm flex items-center">
                <span>thenby</span>
                {/* Sort By  */}
                <button
                  onClick={() => handleIconClick("Delivery Metro aria")}
                  className="ml-2 flex items-center px-2   py-0 bg-gray-300 rounded "
                >
                  DeliveryMetroaria
                  <img
                    src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
                    alt="Image1"
                    className="w-3 h-3 ml-1"
                  />
                  {/* button Pickup */}
                </button>
                <div className=" ml-2">
                  <button
                    onClick={() => handleIconClick("Up Arrow")}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/up-arrow-white.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1  rounded-full bg-red-500 color-white"
                    />
                  </button>
                </div>

                <div className="ml-1">
                  <button
                    onClick={() => handleIconClick("Down Arrow")}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/down-arrow-red.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1"
                    />
                  </button>
                  {/* Arrows with the Images and Background */}
                </div>
              </div>
              {/* Up Arrow ANd Down Arrow After the Aria */}

              <div className="pl-0 lg:pl-12 font-sm flex items-center">
                <span>View</span>
                {/* View */}
                <a>
                  <img
                    src={process.env.PUBLIC_URL + "/images/grid-red.png"}
                    alt="Image1"
                    className="w-6 h-6 ml-1"
                  />
                </a>
                {/* Grid Image*/}
                <a>
                  <img
                    src={process.env.PUBLIC_URL + "/images/brochure.png"}
                    alt="Image1"
                    className="w-5 h-5 ml-1"
                  />
                </a>
                {/* 2nd Grid Image*/}
              </div>

              <div className="pl-0 lg:pl-11 font-sm flex items-center">
                <span>Per Page</span>
                {/* Per Page */}
                <button className="ml-2 flex items-center px-1 py-0 bg-gray-300 rounded ">
                  50
                  <img
                    src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
                    alt="Image1"
                    className="w-3 h-3 ml-1"
                  />
                  {/* button Pickup */}
                </button>
                {/* Per Page */}
              </div>
            </div>
            {/* Flex */}
          </div>
        </div>
        {/* popup container */}
        {Array.map((option, index) => (
          <div
            className={
              activeLink === true
                ? "mb-16 mt-4 lg:mt-7 h-fit rounded-lg overflow-hidden outer-container shadow-lg pt-3 lg:pt-8 border-black border bg-white"
                : "bg-white mt-4 lg:mt-7 mx-0 mb-8 lg:mb-16 h-fit lg:h-56 rounded-lg py-3 lg:py-8 overflow-hidden shadow-lg "
            }
          >
            {/* Miles Amount Div Dummy */}
            <div className="flex lg:-space-x-20 justify-around flex-row lg:flex-row">
              <div className="flex flex-col md:flex-row lg:flex-row">
                <div className="lg:mt-14 ml-6 ">
                  <span className="text-2xl lg:text-5xl font-bold">
                    ${option}
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                    0000 mi @ $0.00/mi
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                    Cash/Certified
                  </span>
                  {/* Miles Amount Div Dummy */}
                </div>
                {/* Vehicle Info */}
                <div className="ml-6 mt-4 lg:ml-12 lg:mt-8">
                  <span className="text-sm lg:text-lg text-slate-400">
                    Vehicle Info
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg">/--/--/--/</span>
                </div>
                {/* Vehicle Info */}

                {/* Company Details And Ratings */}
                <div className="ml-6 mt-4 lg:ml-12 lg:mt-8">
                  <span className="text-sm lg:text-lg text-slate-400">
                    Company
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                    /--/--/--/
                  </span>
                </div>
                {/* Company Details And Ratings */}

                {/* PickUp-Location And Delivery Location And View Route */}
                <div className="ml-6 mt-4 lg:ml-12 lg:mt-8">
                  <span className="text-sm lg:text-lg text-slate-400">
                    PickUp-Location
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                    /--/--/--/
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                    Delivery-Location
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                    /--/--/--/
                  </span>
                  <br></br>

                  <span className="text-sm lg:text-lg text-red-600 mt-2 font-bold">
                    View Route
                  </span>
                  {/* PickUp-Location And Delivery Location And View Route */}
                </div>
              </div>
              <div className="flex flex-col md:flex-row lg:flex-row">
                <div className="ml-6 lg:ml-12 lg:mt-8">
                  <span className="text-sm lg:text-lg text-slate-400">
                    PickUp on or After Date
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                    /--/--/--/
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                    Desired Delivery Date
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                    /--/--/--/
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                    Posted Date
                  </span>
                  {/* PickUp-Location And Delivery Location And Posted Date*/}
                </div>

                <div className="ml-6 mt-4 lg:ml-6 lg:mt-20">
                  <button className=" lg:mx-1 my-1 flex items-center px-2 py-2  bg-red-600 rounded ">
                    <span className="text-white">Call Now</span>
                  </button>
                  <Link to="/InterestedDrivers">
                    <button className=" my-2 py-1  lg:my-1 flex items-center lg:px-2 lg:py-2 bg-red-600 rounded ">
                      <span className="text-white">Interested Driver (4)</span>
                    </button>
                  </Link>
                </div>
                {/* Call Now Button */}
                {/* <div className='ml-6 mt-20'>
            {isCallNowVisible && (
              <button onClick={() => handleIconClick('Pick-Up Location')} className="ml-2 flex items-center px-2 py-0 bg-red-600 rounded ">
                <span className='text-white'>Call Now</span>

              </button>
            )}
            </div> */}
                {/* Call Now Button */}

                {/* Up Arrow ANd Down Arrow After Call Now*/}
                <div className="ml-6 mt-4 lg:ml-8 lg:mt-20">
                  <button
                    onClick={() => {
                      handleIconClick1();
                      setActiveLink(!activeLink);
                    }}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/up-arrow-white.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1  rounded-full bg-red-500 color-white"
                    />
                  </button>
                </div>
              </div>

              {/* Flex for Content under */}
            </div>
            {/* State For DropDown */}
            {/* Lower Container With Infoemation */}
            {isDropdownOpen && (
              <div className="mt-10 mb-6 inner-container bg-white">
                <div className="h-fit border-t-2  border-gray-200 mx-10 pt-8">
                  <div className="flex">
                    {/* Flex */}
                    <div className="ml-8 mt-4">
                      {/* GENERAL INFO Button */}
                      <button
                        onClick={() => handleIconClick("General Info")}
                        className="ml-2  px-3 py-1 bg-red-600 rounded "
                      >
                        <span className="text-white">GENERAL INFO</span>
                      </button>
                      {/* GENERAL INFO Button */}
                    </div>
                    <div className="ml-8 mt-4">
                      {/* PRICE COMPARE Button */}
                      <button
                        onClick={() => handleIconClick("General Info")}
                        className="ml-2  px-3 py-1 bg-gray-100 rounded "
                      >
                        <span className="">PRICE COMPARE</span>
                      </button>
                      {/* PRICE COMPARE Button */}
                    </div>
                    <div className="ml-8 mt-4">
                      {/* GENERAL INFO Button */}
                      <button
                        onClick={() => handleIconClick("General Info")}
                        className="ml-96 content-end px-3 py-1 bg-red-600 rounded "
                      >
                        <span className="text-white">CALL NOW</span>
                      </button>
                      {/* GENERAL INFO Button */}
                    </div>
                    {/* display Flex: */}
                  </div>
                  {/* VEHICLE INFO IN DETAILS */}
                  <div className="flex">
                    <div className="mt-8 ml-10">
                      <span className="">VEHICLE INFO</span>
                      <br></br>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Vehicle</span>
                        <br></br>
                        <span className="text-slate-400">//--//--//</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Weight</span>
                        <br></br>
                        <span>Unspecified</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Dimensions</span>
                        <br></br>
                        <span>Unspecified</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>

                      <div className="mt-4 mb-4">
                        {/* Company Info 1st Company Name */}
                        <span className="text-slate-400">Important Notes</span>
                        <br></br>
                        <span className="text-red-600">Inoperable</span>
                      </div>
                    </div>
                    {/* VEHICLE INFO IN DETAILS */}

                    {/* COMPANY INFO IN DETAILS */}
                    <div className="mt-8 ml-20">
                      <span className="">COMPANY INFO</span>
                      <br></br>
                      <div className="mt-4">
                        <span className="text-slate-400">Company Name</span>
                        <br></br>
                        <span className="text-red-600">//--//--//</span>
                      </div>
                      <div className="mt-4">
                        <span className="text-slate-400">Phone Number</span>
                        <br></br>
                        <span className="text-red-600">00-00000-00</span>
                      </div>
                      <div className="mt-4">
                        <span className="text-slate-400">Hours</span>
                        <br></br>
                        <span className="text-red-600">GMT 5</span>
                      </div>
                      <div className="mt-4">
                        <span className="text-slate-400">
                          Rating In Old System
                        </span>
                        <br></br>
                        <span>0%</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>

                      <div className="mt-4 mb-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Overall Ratings</span>
                        <br></br>
                        <span className="">5.0</span>
                      </div>
                    </div>
                    {/* COMPANY INFO IN DETAILS */}

                    {/* JOB INFO IN DETAILS */}
                    <div className="mt-8 ml-20">
                      <span className="">JOB INFO</span>
                      <br></br>
                      <div className="mt-4">
                        <span className="text-slate-400">Origin</span>
                        <br></br>
                        <span className="text-red-600">//--//--//</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Destination</span>
                        <br></br>
                        <span className="text-red-600">Unspecified</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Price</span>
                        <br></br>
                        <span className="">00.00$ @ 0.93mi</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>

                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Order ID</span>
                        <br></br>
                        <span>000-00</span>
                      </div>
                    </div>
                    {/* JOB INFO IN DETAILS */}

                    {/* DATES IN DETAILS */}
                    <div className="mt-8 ml-20">
                      <span className="">DATES</span>
                      <br></br>
                      <div className="mt-4">
                        <span className="text-slate-400">
                          Pick-up on or After Date
                        </span>
                        <br></br>
                        <span className=" font-bold">DD/MM/YYYY</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">
                          Desired Delivery Date
                        </span>
                        <br></br>
                        <span className="font-bold">DD/MM/YYYY</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Posted Date</span>
                        <br></br>
                        <span className="">DD/MM/YYYY</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      {/* DATES IN DETAILS */}
                    </div>
                    {/* MISC IN DETAILS */}
                    <div className="mt-8 ml-12">
                      <span className="">MISC</span>
                      <br></br>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Additional Info</span>
                        <br></br>
                        <span className=" font-bold">Abcd</span>
                      </div>
                      {/* MISC IN DETAILS */}
                    </div>
                    {/* Flex for Lower Content */}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </Interface>
  );
}
