import React from "react";
import Interface from "../../Rcompo/Interface";

export default function CompanyProfile() {
  return (
    <Interface>
      <>
        <div className=" mx-7 md:mx-10   lg:mx-4 lg:px-8">
          <div className="text-xl sm:text-xl lg:text-3xl mt-6 mb-4 ml-8">
            Company Profile
          </div>
          <div className="border  w-full h-fit mt-6  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-2 mb-2 bg-slate-300  rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Account Information</p>
            </div>
            <div className="px-4 py-4">
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Company ID:{" "}
                </span>
                <span className="text-sm lg:text-md">tmxusa</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Marketplace(s):{" "}
                </span>
                <span className="text-sm lg:text-md"> Central Dispatch</span>
              </div>

              <div className="flex justify-between mb-2">
                <div>
                  <span className="lg:text-md text-sm ml-4 font-semibold">
                    Password:{" "}
                  </span>
                  <span className="text-sm lg:text-md">******* </span>
                </div>
                <div>
                  <div>
                    <button className="text-blue-900 text-xs mr-6 font-bold hover:underline">
                      [ Change Password ]
                    </button>
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Business Type:
                </span>
                <span className="text-sm lg:text-md"> Dealer</span>
              </div>

              <div className="mb-6">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Rating Access:{" "}
                </span>
                <span className="text-sm lg:text-md"> FULL/Access</span>
              </div>
            </div>
          </div>

          <div className="border  w-full h-fit mt-4  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-2 mb-4 bg-slate-300  rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Company Information</p>
            </div>
            <div className="py-4 px-4">
              <div className="mb-3">
                <p className="text-sm lg:text-md ml-4 font-semibold">
                  TMX Finance:{" "}
                </p>
                <p className="text-sm lg:text-md ml-4">
                  309 E.White Horse Pike,
                </p>
                <p className="text-sm lg:text-md ml-4">Galloway, NJ 08205</p>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Owner/Manager:{" "}
                </span>
                <span className="text-sm lg:text-md">Vladimir Senko</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Website:{" "}
                </span>
                <span className="text-sm lg:text-md"></span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Hours:{" "}
                </span>
                <span className="text-sm lg:text-md">10AM-5PM Mon-Fri</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Timezone:{" "}
                </span>
                <span className="text-sm lg:text-md">EST</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Established:{" "}
                </span>
                <span className="text-sm lg:text-md">2021</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Company Description:{" "}
                </span>
                <span className="text-sm lg:text-md"> Used car dealership</span>
              </div>
            </div>
          </div>

          <div className="border  w-full h-fit mt-4  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-4 mb-4 bg-slate-300  rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Contact Information</p>
            </div>
            <div className="px-4 py-4">
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Dispatch Contact:{" "}
                </span>
                <span>Walter</span>
              </div>
              <div className="mb-3">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Preferred Contact Method:{" "}
                </span>
                <span>Any</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Listings:{" "}
                </span>
                <span className="text-sm lg:text-md">091-33943-23</span>
              </div>
              <div className="mb-3">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Local:{" "}
                </span>
                <span className="text-sm lg:text-md">091-33943-23</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Email:{" "}
                </span>
                <span className="text-sm lg:text-md">adeel@gmail.com</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Ratings Emails at:{" "}
                </span>
                <span className="text-sm lg:text-md">adeel@gmail.com</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Dispatch Emails at:{" "}
                </span>
                <span className="text-sm lg:text-md">adeel@gmail.com</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Company Description:{" "}
                </span>
                <span className="text-sm lg:text-md"> Used car dealership</span>
              </div>
            </div>
          </div>

          <div className="border w-full mb-8 h-fit mt-4  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold h-fit py-4 px-4 mb-4 bg-slate-300  rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Reference Information</p>
            </div>
            <div className="mb-2 px-4 py-4">
              <span className="text-sm lg:text-md ml-4 font-semibold">
                Driving Liscense #:{" "}
              </span>
              <span className="text-sm lg:text-md"> DL 07220U</span>
            </div>
          </div>

          <div className="border  w-full mb-8 h-fit mt-4  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-2 mb-4 bg-slate-300  rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Billing Information</p>
            </div>
            <div className="px-4 py-4">
              <div className="mb-3">
                <p className="text-sm lg:text-md ml-4 font-semibold">
                  Vladimir Senko:{" "}
                </p>
                <p className="text-sm lg:text-md ml-4">18 LUCY LOOP,</p>
                <p className="text-sm lg:text-md ml-4">
                  Staten Island, NY 10312
                </p>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Phone:{" "}
                </span>
                <span className="text-sm lg:text-md"> 021-07220U</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Driving Liscense #:{" "}
                </span>
                <span className="text-sm lg:text-md"> DL 07220U</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Dispatch Emails at:{" "}
                </span>
                <span className="text-sm lg:text-md">adeel@gmail.com</span>
              </div>
            </div>
          </div>
        </div>
      </>
    </Interface>
  );
}
