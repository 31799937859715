import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { options1, options2, Year, Make, Model } from "./data";
import Location from "./Components/ShipperPages/Location";
import Cars from "./Components/ShipperPages/Cars";
import Shipmentdate from "./Components/ShipperPages/Shipmentdate";
import { useState } from "react";
import Interface from "./Rcompo/Interface";
import SearchVehicle from "./Components/ShipperPages/SearchVehicle";
import DriverInterface from "./Rcompo/DriverInterface";
import FindShipments from "./Components/DriverPages/FindShipments";
import PostDriverVehicle from "./Components/DriverPages/PostDriverVehicle";
import UserPropfile from "./Components/CommanPages/UserPropfile";
import CompanyProfile from "./Components/CommanPages/CompanyProfile";
import MyVehicles from "./Components/DriverPages/MyVehicles";
import MyVehicleDelivered from "./Components/DriverPages/MyVehicleDelivered";
import InterestedDrivers from "./Components/ShipperPages/InterestedDrivers";
import Listed from "./Components/DriverPages/Listed";
import MyShipment from "./Components/ShipperPages/MyShipmet";
import Payment from "./Components/ShipperPages/Payment";
import Billings from "./Components/CommanPages/Billings";
import Contracts from "./Components/CommanPages/Contracts";
import InterestedShipper from "./Components/ShipperPages/InteresstedShipper";
import DriverDashboard from "./Components/DriverPages/DriverDashboard";
import ShiperDashboard from "./Components/ShipperPages/ShiperDashboard";
import Network from "./Components/CommanPages/Network";
import Preview from "./Components/ShipperPages/Preview";
import OTPScreen from "./Components/LoginSignups/SignUp/OTP";
import ForgetPassword from "./Components/LoginSignups/Login/Forget";
import OTPPage from "./Components/LoginSignups/Login/OTPPage";
import ChangePassword from "./Components/LoginSignups/Login/ChangePassword";
import Signup from "./Components/LoginSignups/SignUp/Signup";
import LoginSignup from "./Components/LoginSignups/Login/LoginSignup";


function App() {
  const [sharedData, setSharedData] = useState("");
  const handleDataChange = (newData) => {
    setSharedData(newData);
  };
  return (
    <Router>
      <div>
        {/* Navbar */}
        {/* <div >
          <Interface/>
        </div> */}
        <Routes>
          <Route exact path="/" element={<LoginSignup/>} />
          <Route exact path="/Signup" element={<Signup/>} />
          <Route
            path="/Interface"
            element={((<Interface />), (<ShiperDashboard />))}
          />
          <Route
            path="/DriverInterface"
            element={((<DriverInterface />), (<DriverDashboard />))}
          />
          <Route path="/DriverDashboard" element={<DriverDashboard />} />
          <Route path="/ShipperDashboard" element={<ShiperDashboard />} />
          <Route path="IntrestedShipper" element={<InterestedShipper />} />
          <Route
            path="MyVehicles"
            element={((<MyVehicles />), (<MyVehicleDelivered />))}
          />
          <Route
            exact
            path="/dest"
            element={
              <Cars
                sharedData={sharedData}
                head1="Cars details"
                label3="Year"
                label4="Model"
                label5="Make"
                label6="VIN (Vehicle Identification Number)"
                Year={Year}
                Make={Make}
                Model={Model}
                buttonLabel1="Confirm Detail"
              />
            }
          />
          <Route
            exact
            path="/PostVehicle"
            element={
              <PostDriverVehicle
                sharedData={sharedData}
                head1="Cars details"
                label3="Year"
                label4="Model"
                label5="Make"
                label6="VIN (Vehicle Identification Number)"
                label7="Registration No."
                label8="Truck Size?"
                Year={Year}
                Make={Make}
                Model={Model}
                buttonLabel1="Confirm Details"
              />
            }
          />
          <Route
            exact
            path="/PostShipment"
            element={
              <Location
                onDataChange={handleDataChange}
                head="Location"
                label="Enter Number (optional)"
                label1="Pickup Location"
                label2="Destination"
                options1={options1}
                options2={options2}
                buttonLabel="Confirm Location"
              />
            }
          />
          <Route
            exact
            path="/Shipment-Date"
            element={<Shipmentdate head2="Select a date for shipment:" />}
          />
          <Route path="Payment" element={<Payment />} />
          <Route exact path="SearchVehicle" element={<SearchVehicle />} />
          <Route
            exact
            path="/MyShipment"
            element={<MyShipment Sort="Status" />}
          />
          <Route path="InterestedDrivers" element={<InterestedDrivers />} />
          <Route exact path="/MyProfile" element={<UserPropfile />} />
          <Route exact path="/CompanyProfile" element={<CompanyProfile />} />
          <Route
            exact
            path="/FindShipments"
            element={<FindShipments Sort="pickup-location" />}
          />
          <Route path="/Delivered" element={<MyVehicleDelivered />} />
          <Route path="Listed" element={<Listed />} />
          <Route path="ListedVehicle" element={<Listed />} />
          <Route path="Billing" element={<Billings />} />
          <Route path="Contracts" element={<Contracts />} />
          <Route path="Network" element={<Network />} />
          <Route path="Preview" element={<Preview />} />
           <Route path="OTP" element={<OTPScreen/>} />
          <Route path="ForgetPassword" element={<ForgetPassword/>} />
          <Route path="Forget-OTP" element={<OTPPage/>} />    
         <Route path="Change-Password" element={<ChangePassword/>} /> 
        </Routes>
      </div>
    </Router>
  );
}
export default App;