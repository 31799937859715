import React , {useEffect, useState}from "react";
import Interface from "../../Rcompo/Interface";
import globalAPIAddress from "../GlobalPages/GlobalVar";
export default function UserPropfile() {
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [business, setBusiness] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${globalAPIAddress}/MyProfile`);
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        const result = await response.json();
        if (response.ok) {
          setFname(result.Fname);
          setLname(result.Lname);
          setEmail(result.email);
          setPassword(result.password);
          setBusiness(result.bussiness);
          console.log(result.callusername);
        } else {
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <Interface>
      <>
        <div className="h-full mx-7 md:mx-10   lg:mx-4 md:px-8 sm:px-2">
          <div className="text-xl sm:text-xl lg:text-3xl font-medium mt-6 mb-4 ml-8 font-sans">
            Personal Info
          </div>
          <div className="border w-full mb-16 h-fit mt-6  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold h-fit py-2 mb-4 bg-slate-300  rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Reference Information</p>
            </div>
            <div className="px-8 py-4 md:px-4 md:py-4 sm:px-1 sm:py-1">
              <div className="flex justify-between ">
                <div className="mb-4">
                  <label className="text-sm text-gray-500">First Name</label>
                  <p className="text-sm">{Fname}</p>
                </div>
                <div>
                  <button className="text-blue-900 text-xs  font-bold">
                    EDIT
                  </button>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="mb-4">
                  <label className="text-sm text-gray-500">Last Name</label>
                  <p className="text-sm">{Lname}</p>
                </div>
                <div>
                  <button className="text-blue-900 text-xs  font-bold">
                    EDIT
                  </button>
                </div>
              </div>

              <hr className="mb-2"></hr>

              <div className="mb-4">
                <label className="text-sm text-gray-500">Username</label>
                <p className="text-sm">{Fname}{Lname}</p>
              </div>

              <div className="flex justify-between">
                <div className="mb-4">
                  <label className="text-xs text-gray-500">Contact Email</label>
                  <p className="text-sm">{email}</p>
                </div>
                <div>
                  <button className="text-blue-900 text-xs font-bold">
                    CHANGE
                  </button>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="mb-4">
                  <label className="text-sm text-gray-500">
                    {/* Recovery Mobile Phone Number */}Business Type
                  </label>
                  <p className="text-sm">{business}</p>
                </div>
                <div>
                  <button className="text-blue-900 text-xs font-bold">
                    ADD
                  </button>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="mb-4">
                  <label className="text-sm text-gray-500">Password</label>
                  <p className="text-sm">{password}</p>
                </div>
                <div>
                  <button className="text-blue-900 text-xs font-bold">
                    RESET
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Interface>
  );
}
