import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function Sidebar(props) {
  let linkto = "";
  if (props.page === "driver") {
    linkto = "DriverDashboard";
  } else {
    linkto = "ShipperDashboard";
  }
  const [isVisible, setIsVisible] = useState(false);
  const [isDriver, setisDriver] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFindShipmentDropdownOpen, setIsFindShipmentDropdownOpen] =
    useState(false);
  useEffect(() => {
    if (props.page === "driver") {
      setisDriver(true);
    }
  }, [props.page]);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const toggleDropdown = (dropdownName) => {
    if (dropdownName === "main") {
      setIsDropdownOpen(!isDropdownOpen);
    } else if (dropdownName === "findShipment") {
      setIsFindShipmentDropdownOpen(!isFindShipmentDropdownOpen);
    }
  };

  let options = [];
  let findShipmentOptions = [];
  let firstoption = "ShipVehicles";
  let secondoption = "Find Shipments";

  if (props.page === "shipper") {
    options = ["PostShipment"];
    findShipmentOptions = ["SearchVehicle", "MyShipment"];
  } else {
    options = ["FindShipments"];
    findShipmentOptions = ["PostVehicle", "MyVehicles"];
    firstoption = "FindVehicles";
    secondoption = "RegisterVehicle";
  }

  return (
    <div className=" flex lg:flex-row flex-col justify-between  lg:px-36 py-3 border-b-2  border-yellow-600 lg:border-b-0">
      <div className="flex flex-row justify-between   px-2  ">
        <img
          src={process.env.PUBLIC_URL + "/images/logo-QTL.png"}
          alt="Messages"
          className="lg:h-9 h-9  mr-2  mt-1"
        />
        <div className="lg:hidden flex justify-end ">
          <button
            onClick={toggleVisibility}
            className="text-black focus:outline-none"
          >
            <img
              src={process.env.PUBLIC_URL + "/images/hamburger.png"}
              alt="Messages"
              className="lg:h-7  h-5 lg:w-7 w-4 mt-1.5"
            />
          </button>
        </div>
      </div>
      <div>
        <div className={`lg:flex ${isVisible ? "" : "hidden"}`}>
          <div>
            <Link to={`/${linkto}`}>
              <div
                className="my-2 ml-0 lg:ml-10 flex items-center cursor-pointer w-full  lg:w-fit "
                onClick={() => setActiveLink("ListedVehicle")}
              >
                <span style={{color:'#494C4F'}} className="font-outfit text-14   w-full lg:w-fit  lg:text-16 flex mt-4 lg:mt-0 px-2 border-b-2  border-red-100 lg:border-b-0">
                  Dashboard
                </span>
              </div>
            </Link>
          </div>

          <div
            className=" ml-0 lg:ml-10   flex items-center w-full   lg:w-fit  cursor-pointer "
            onClick={() => toggleDropdown("main")}
          >
            <span style={{color:'#494C4F'}} className=" font-outfit text-14 w-full lg:w-fit lg:text-16  px-2  border-b-2  border-red-100 lg:border-b-0">
              {firstoption}
            </span>
            <img
              src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
              alt="Dropdown"
              className="w-5  mr-1 border-b-2  border-red-100 lg:border-b-0"
            />
          </div>
          {isDropdownOpen && (
            <div className="ml-0 lg:ml-40   lg:absolute lg:top-24 lg:right-96  ">
              {options.map((option, index) => (
                <Link
                  key={index}
                  to={`/${option}`}
                  onClick={() => setActiveLink(option)}
                >
                  <div
                    className={` border-b shadow-sm rounded-sm border-red-100 w-full lg:w-48 h-10 bg-slate-50   hover:bg-red-600 px-4 py-2 lg:px-2 lg:py-2  hover:text-white ${
                      activeLink === option
                        ? "bg-red-600 text-white"
                        : "bg-gray-100"
                    }`}
                  >
                    <span className="text-14 lg:text-16 font-outfit">
                      {option}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          )}
          <div
            className="mt-2 lg:mt-1 lg:ml-7 w-full   lg:w-fit flex items-center cursor-pointer"
            onClick={() => toggleDropdown("findShipment")}
          >
            <span style={{color:'#494C4F'}} className="font-outfit text-14 w-full lg:w-fit lg:text-16  px-2  border-b-2  border-red-100 lg:border-b-0">
              {secondoption}
            </span>
            <img
              src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
              alt="Dropdown"
              className=" w-5 mr-1 border-b-2  border-red-100 lg:border-b-0"
            />
          </div>
          {isFindShipmentDropdownOpen && (
            <div className="  lg:absolute top-24   right-40">
              {findShipmentOptions.map((option, index) => (
                <Link
                  key={index}
                  to={`/${option}`}
                  className=""
                  onClick={() => setActiveLink(option)}
                >
                  <div
                    className={` border-b  rounded-sm border-red-100  w-full lg:w-48 h-10 bg-slate-50  hover:bg-red-600 px-4 py-2 lg:px-2 lg:py-2  hover:text-white ${
                      activeLink === option
                        ? "bg-red-600 text-white"
                        : "bg-gray-100"
                    }`}
                  >
                    <span className="text-14 lg:text-16 font-outfit  ">
                      {option}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          )}
          {isDriver && (
            <Link to="/ListedVehicle">
              <div
                className=" my-2 lg:mt-3 ml-0 lg:ml-10 flex items-center cursor-pointer"
                onClick={() => setActiveLink("ListedVehicle")}
              >
                {/* <img
                src={process.env.PUBLIC_URL + "/images/car.png"}
                alt="Messages"
                className="w-5 mr-2"
              /> */}
                <span style={{color:'#494C4F'}} className="font-outfit text-14 w-full lg:w-fit lg:text-16  px-2  border-b-2  border-red-100 lg:border-b-0">
                  ListedVehicle
                </span>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
