import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UserProfileIcon from "../../Rcompo/UserProfileIcon";
// import axios from "axios"
import globalAPIAddress from "../GlobalPages/GlobalVar"
export default function Navbar() {
  // const customHeight = "14px";
  // const [isProfileopen, setIsProfileopen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  let options = [
    "MyProfile",
    "CompanyProfile",
    "Billing",
    "Contracts",
    "Network",
    "LOGOUT",
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleIconClick = (icon) => {
    alert(`Clicked ${icon} icon`);
  };
  const [user, setUser] = useState([]);
  const [name, setLname] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${globalAPIAddress}/Navbar`);
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        const result = await response.json();
        if (response.ok) {
          setUser(result.callusername);
          setLname(result.name);
          console.log(result.callusername);
        } else {
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div
      style={{ backgroundColor: "#e8e8ed" }}
      className="  flex justify-end items-center px-8 lg:px-36 py-1"
    >
      {/* <span className="text-2xl text-black">
        QUICK <span className="font-bold ">TRANSPORT </span>LINE
      </span> */}
      <div className="flex items-center">
        <div className="flex items-center justify-end ml-5">
          <button
            onClick={() => handleIconClick("Messages")}
            className="icon-button"
          >
            <img
              src={process.env.PUBLIC_URL + "/images/Message-QTL.png"}
              alt="Messages"
              className="w-5 lg:w-6  mr-3"
            />
          </button>
          <button
            onClick={() => handleIconClick("Settings")}
            className="icon-button"
          >
            <img
              src={process.env.PUBLIC_URL + "/images/Settings-QTL.png"}
              alt="Settings"
              className="w-5 lg:w-6 mr-3"
            />
          </button>
        </div>

        <div className="border-l-2 ml-5 border-gray-500 h-10" />

        <button onClick={() => toggleDropdown("main")}>
          <div className="flex ml-3">
            <UserProfileIcon />
            <div className="font-outfit font-semibold ml-1  text-14 lg:text-16 mt-0.5">
              {user} {name}
            </div>
            <img
              src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
              alt="Dropdown"
              className="w-5 lg:w-6  mt-0.5 ml-1"
            />
          </div>
        </button>

        {isDropdownOpen && (
          <div className="ml-7 pl-16 pt-4 absolute top-10 right-2">
            {options.map((option, index) => (
              <Link
                key={index}
                to={`/${option}`}
                onClick={() => setActiveLink(option)}
              >
                <div
                  className={` border w-48 h-10 bg-gray-100  hover:bg-red-600 p-2  hover:text-white ${
                    activeLink === option
                      ? "bg-red-600 text-white"
                      : "bg-gray-100"
                  }`}
                >
                  {option === "LOGOUT" ? (
                    <Link to="/">
                      <div className="flex">
                        <span>{option}</span>
                        <img
                          src={process.env.PUBLIC_URL + "/images/logout.png"}
                          alt="Logout"
                          className="ml-20  mt-0.5 h-6 "
                        />
                      </div>
                    </Link>
                  ) : (
                    <span>{option}</span>
                  )}
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
