import React, { useState } from "react";
import Interface from "../../Rcompo/Interface";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"
import globalAPIAddress from "../GlobalPages/GlobalVar";
export default function Shipmentdate({ head2 }) {
const [From,setFrom] = useState("");
const [To,setTo] = useState("");
const navigate = useNavigate();
const HandleSubmit = () => {
  axios.post(`${globalAPIAddress}/Shipment-Date`,
  {
    From: From,
    To:To
  }
  ).then(response => {
      console.log(response.data.message);
      navigate("/Payment")
  }).catch(error => {
       console.log(error.response.data); 
  });
  }

  return (
    <Interface>
      <div className=" mx-7 md:mx-10 lg:mx-32 px-4 lg:px-10 py-4 lg:py-5 max-w-full mt-4 lg:mt-10 mb-8 lg:mb-24 bg-white shadow-2xl shadow-gray-300 rounded-md lg:rounded-lg">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="col-span-1 lg:col-span-2">
            <h2 className="font-semibold text-xl mb-4">{head2}</h2>
            <div className="mb-4">
              <label
                htmlFor="fromDate"
                className="block font-semibold text-lg text-gray-700"
              >
                From:
              </label>
              <input
                type="date"
                id="fromDate"
                name="fromDate"
                className="mt-1 p-3 block w-full h-11  lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
              onChange={(e)=>{
                setFrom(e.target.value);
              }}

              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="toDate"
                className="block font-semibold text-lg text-gray-700"
              >
                To:
              </label>
              <input
                type="date"
                id="toDate"
                name="toDate"
                className="mt-1 p-3 block w-full h-11  lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
              
                onChange={(e)=>{
                setTo(e.target.value);
              }}
              />
            </div>
          </div>
        </div>
        <div className="mt-7 lg:mt-14 flex justify-start space-x-16">
          <Link to="/Dest">
            <button className="bg-gray-600 hover:bg-gray-500 text-white font-bold py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap">
              Back
            </button>
          </Link>
          {/* <Link to="/Payment"> */}
            <button onClick={HandleSubmit}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap">
              Proceed to payment
            </button>
          {/* </Link> */}
        </div>
      </div>
    </Interface>
  );
}
