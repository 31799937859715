import React, { useState } from "react";
import Interface from "../../Rcompo/Interface";
import { Link } from "react-router-dom";
import DriverInterface from "../../Rcompo/DriverInterface";

export default function Cars({
  head1,
  label3,
  label4,
  label5,
  label6,
  label7,
  label8,
  Year,
  Make,
  Model,
  buttonLabel1,
}) {
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleUpload = () => {
    // Perform upload logic here (e.g., send selectedFile to server)
    console.log("File uploaded:", selectedFile);
    // Reset selected file after upload
    setSelectedFile(null);
  };

  const handleOption1Change = (e) => {
    setOption1(e.target.value);
  };

  const handleOption2Change = (e) => {
    setOption2(e.target.value);
  };
  const handleOption3Change = (e) => {
    setOption3(e.target.value);
  };
  return (
    <DriverInterface>
      <div className="mx-7 md:mx-10   lg:mx-36 px-4 lg:px-10 py-4 lg:py-5 max-w-full mt-4 lg:mt-10 mb-8 lg:mb-24 bg-white shadow-2xl shadow-gray-300 rounded-md lg:rounded-lg">
        <h2 className="font-semibold text-xl mb-4">{head1}</h2>
        <div className="grid lg:grid-cols-2 gap-4">
          <div className="col-span-1">
            <div className="pr-4">
              <div className="mb-4">
                <label
                  htmlFor="dropdown1"
                  className="block mb-1 font-semibold text-gray-700"
                >
                  {label3}
                </label>
                <select
                  id="dropdown1"
                  className=" w-full  h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  value={option1}
                  onChange={handleOption1Change}
                >
                  <option value="">Select a {label3}</option>
                  {Year.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="Text"
                  className="block mb-1 font-semibold text-gray-700"
                >
                  {label4}
                </label>
                <select
                  id="Text"
                  className="w-full  h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  value={option2}
                  onChange={handleOption2Change}
                >
                  <option value="">Select a {label4}</option>
                  {Model.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="dropdown3"
                  className="block mb-1 font-semibold text-gray-700"
                >
                  {label5}
                </label>
                <select
                  id="dropdown3"
                  className="w-full  h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  value={option3}
                  onChange={handleOption3Change}
                >
                  <option value="">Select a {label5}</option>
                  {Make.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-span-1 space-y-4">
            <div>
              <label className="block font-semibold text-gray-700 ">
                {label6}
                <br></br>
                <input
                  className="w-auto   mt-1 h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  // style={{width:'500px'}}
                  type="text"
                  placeholder="1727"
                />
              </label>
            </div>
            <div>
              <label className="  font-semibold text-gray-700 ">
                {label7}
                <br></br>
                <input
                  className="w-auto   mt-1 h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  // style={{width:'500px'}}
                  type="text"
                  placeholder="547263463"
                />
              </label>
            </div>
            <div>
              <label className="block font-semibold text-gray-700 ">
                {label8}
                <br></br>
                <input
                  className="w-auto   mt-1 h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  // style={{width:'500px'}}
                  type="text"
                  placeholder="20kg"
                />
              </label>
            </div>

            <div className="flex items-center mt-5 ">
              <span className="block mb-1 font-medium text-gray-700">
                Is it operable?
              </span>
              <label className="inline-flex items-center mr-4">
                <input
                  type="radio"
                  className="ml-3 form-radio h-5 w-5 text-indigo-600"
                  value="option1"
                  checked={selectedOption === "Opened"}
                  onChange={() => handleOptionChange("Opened")}
                />
                <span className="ml-2 ">Yes</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio h-5 w-5 text-indigo-600"
                  value="option2"
                  checked={selectedOption === "Enclosed"}
                  onChange={() => handleOptionChange("Enclosed")}
                />
                <span className="ml-2">Enclosed</span>
              </label>
            </div>
            <div className="">
              <span className="block mt-1 font-semibold lg:font-semibold   text-gray-700 mb-4">
                Documentation related to the vehicle's ownership( registration
                and proof of insurance.)
              </span>
              <input
                type="file"
                onChange={handleFileChange}
                className="  py-2 px-1  lg:px-4 "
              />
              {selectedFile && (
                <div className="mt-4">
                  <p className="font-medium">
                    Selected File: {selectedFile.name}
                  </p>
                  <button
                    onClick={handleUpload}
                    className="mt-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
                  >
                    Upload
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-7 lg:mt-14 flex justify-start space-x-24 ml-1 ">
          <Link>
            <button className="bg-red-500 hover:bg-red-600 text-white font-bold  py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap">
              {buttonLabel1}
            </button>
          </Link>
        </div>
      </div>
    </DriverInterface>
  );
}
