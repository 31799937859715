import React, { useState, useEffect } from "react";
// import Interface from '../Rcompo/Interface';
import { useNavigate } from "react-router-dom";
import Interface from "../../Rcompo/Interface";
import globalAPIAddress from "../GlobalPages/GlobalVar";
import axios from "axios";
const Card = ({
  head,
  label,
  label1,
  label2,
  options1,
  options2,
  buttonLabel,
  onSubmitData
}) => {
  const navigate = useNavigate();
  const [Pickup, setPickup] = useState("");
  const [Dest, setDest] = useState("");
  const [Phonenum, setPhonenum] = useState("");
  const [Transtype, setTranstype] = useState(null);

  const handlePickup = (e) => {
    setPickup(e.target.value);
  };

  const handleDest = (e) => {
    setDest(e.target.value);
  };

  const handleOptionChange = (value) => {
    setTranstype(value);
  };
  useEffect(() => {
    localStorage.setItem("Transtype", Transtype);
    localStorage.setItem("option1", Pickup);
    localStorage.setItem("option2", Dest);
  }, [Transtype, Pickup, Dest]);

  useEffect(() => {
    const storedTranstype = localStorage.getItem("Transtype");
    const storedOption1 = localStorage.getItem("Pickup");
    const storedOption2 = localStorage.getItem("Dest");
    if (storedTranstype !== null) setTranstype(storedTranstype);
    if (storedOption1 !== null) setPickup(storedOption1);
    if (storedOption2 !== null) setDest(storedOption2);
  }, []);

  const HandleSubmit = () => {
  axios.post(`${globalAPIAddress}/PostShipment`,
  {
    pickup_location : Pickup,
    destinationLoc: Dest,
    Phonenumber: Phonenum,
    TT: Transtype,
  }
  ).then(response => {
      console.log(response.data.message);
      navigate("/Dest")
  }).catch(error => {
       console.log(error.response.data); 
  });
  }
  return (
    <Interface>
      {/* */}
      <div className="mx-7 md:mx-10 lg:mx-32 px-4 lg:px-10 py-4 lg:py-5 max-w-full mt-4 lg:mt-10 mb-2 lg:mb-0 bg-white shadow-2xl shadow-gray-300 rounded-md  ">
        <div className="w-full">
          <h2 className="font-bold text-lg lg:text-xl mb-2 lg:mb-4">
            Post a Shipment
          </h2>
        </div>
        <h2 className="font-semibold text-lg lg:text-xl mb-2 lg:mb-4">
          {head}
        </h2>
        <div className="mb-2 lg:mb-4">
          <label
            htmlFor="dropdown1"
            className="block mb-1 font-medium text-gray-700"
          >
            {label1}
          </label>
          <select
            id="dropdown1"
            className="w-full h-11 lg:h-16 border-2 border-gray-300 rounded-xl py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
            value={Pickup}
            onChange={handlePickup}
          >
            <option className=" bg-yellow-500" value="" disabled>
              Select a {label1}
            </option>
            {options1.map((option, index) => (
              <option key={index} value={option.label}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-2 lg:mb-4">
          <label
            htmlFor="dropdown2"
            className="block mb-1 font-medium text-gray-700"
          >
            {label2}
          </label>
          <select
            id="dropdown2"
            className="w-full h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
            value={Dest}
            onChange={handleDest}
          >
            <option className="text-sm" value="" disabled>
              Select a {label2}
            </option>
            {options2.map((option, index) => (
              <option key={index} value={option.label}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
            {label}
            <input
              className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
              type="text"
              placeholder="Enter receiver number"
              onChange={(e)=>{
                setPhonenum(e.target.value);
              }}
            />
          </label>
        </div>
        <div className="flex items-center mt-5 ">
          <span className="block mb-1 font-medium text-gray-700">
            Transport{""}Type:
          </span>
          <label className="inline-flex items-center mr-1 lg:mr-4">
            <input
              type="radio"
              className="ml-2 lg:ml-3 form-radio h-5 w-5 text-indigo-600"
              value="option1"
              checked={Transtype === "Opened"}
              onChange={() => handleOptionChange("Opened")}
            />
            <span className="ml-1 lg:ml-3 ">Open</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio h-5 w-5 text-indigo-600"
              value="option2"
              checked={Transtype === "Enclosed"}
              onChange={() => handleOptionChange("Enclosed")}
            />
            <span className="ml-1 lg:ml-3">Enclosed</span>
          </label>
        </div>

        <div className="w-full mt-8 lg:mt-14">
          <div className="w-full lg:w-auto">
            {/* <Link to="/Dest"> */}
              <button onClick={HandleSubmit}
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap">
                {buttonLabel}
              </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </Interface>
  );
};

export default Card;
